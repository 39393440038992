"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const ColonyBenefit_1 = require("@/common/colonies/ColonyBenefit");
exports.default = vue_1.default.extend({
    name: 'BuildBenefit',
    props: {
        metadata: {
            type: Object,
            required: true,
        },
        idx: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ColonyBenefit() {
            return ColonyBenefit_1.ColonyBenefit;
        },
        buildQuantityText() {
            return this.metadata.buildQuantity[this.idx] > 1 ? String(this.metadata.buildQuantity[this.idx]) : '';
        },
        resource() {
            var _a, _b;
            return (_b = ((_a = this.metadata.buildResource) !== null && _a !== void 0 ? _a : this.metadata.cardResource)) === null || _b === void 0 ? void 0 : _b.toLowerCase();
        },
    },
});
