"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AndOptions_vue_1 = require("@/client/components/AndOptions.vue");
const OrOptions_vue_1 = require("@/client/components/OrOptions.vue");
const SelectAmount_vue_1 = require("@/client/components/SelectAmount.vue");
const SelectCard_vue_1 = require("@/client/components/SelectCard.vue");
const SelectPayment_vue_1 = require("@/client/components/SelectPayment.vue");
const SelectProjectCardToPlay_vue_1 = require("@/client/components/SelectProjectCardToPlay.vue");
const SelectInitialCards_vue_1 = require("@/client/components/SelectInitialCards.vue");
const SelectOption_vue_1 = require("@/client/components/SelectOption.vue");
const SelectPlayer_vue_1 = require("@/client/components/SelectPlayer.vue");
const SelectSpace_vue_1 = require("@/client/components/SelectSpace.vue");
const SelectDelegate_vue_1 = require("@/client/components/SelectDelegate.vue");
const SelectParty_vue_1 = require("@/client/components/SelectParty.vue");
const SelectColony_vue_1 = require("@/client/components/SelectColony.vue");
const SelectProductionToLose_vue_1 = require("@/client/components/SelectProductionToLose.vue");
const ShiftAresGlobalParameters_vue_1 = require("@/client/components/ShiftAresGlobalParameters.vue");
const SelectGlobalEvent_vue_1 = require("@/client/components/SelectGlobalEvent.vue");
const typeToComponentName = {
    'and': 'and-options',
    'card': 'SelectCard',
    'projectCard': 'SelectProjectCardToPlay',
    'initialCards': 'SelectInitialCards',
    'or': 'or-options',
    'option': 'select-option',
    'payment': 'SelectPayment',
    'space': 'select-space',
    'player': 'select-player',
    'amount': 'select-amount',
    'delegate': 'select-delegate',
    'party': 'select-party',
    'colony': 'select-colony',
    'productionToLose': 'select-production-to-lose',
    'aresGlobalParameters': 'shift-ares-global-parameters',
    'globalEvent': 'select-global-event',
};
exports.default = vue_1.default.component('player-input-factory', {
    props: {
        players: {
            type: Array,
        },
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    components: {
        'and-options': AndOptions_vue_1.default,
        'or-options': OrOptions_vue_1.default,
        'select-amount': SelectAmount_vue_1.default,
        SelectCard: SelectCard_vue_1.default,
        'select-option': SelectOption_vue_1.default,
        SelectPayment: SelectPayment_vue_1.default,
        SelectProjectCardToPlay: SelectProjectCardToPlay_vue_1.default,
        SelectInitialCards: SelectInitialCards_vue_1.default,
        'select-player': SelectPlayer_vue_1.default,
        'select-space': SelectSpace_vue_1.default,
        'select-delegate': SelectDelegate_vue_1.default,
        'select-party': SelectParty_vue_1.default,
        'select-colony': SelectColony_vue_1.default,
        SelectProductionToLose: SelectProductionToLose_vue_1.default,
        ShiftAresGlobalParameters: ShiftAresGlobalParameters_vue_1.default,
        SelectGlobalEvent: SelectGlobalEvent_vue_1.default,
    },
    methods: {
        saveData() {
            this.$children[0].saveData();
        },
        canSave() {
            const canSave = this.$children[0].canSave;
            return canSave ? canSave() : true;
        },
    },
    computed: {
        componentName() {
            return typeToComponentName[this.playerinput.type];
        },
    },
});
