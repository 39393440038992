"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const CardName_1 = require("@/common/cards/CardName");
const GameModule_1 = require("@/common/cards/GameModule");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const CardType_1 = require("@/common/cards/CardType");
function corpCardNames(module) {
    return (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byModule)(module))
        .filter((0, ClientCardManifest_1.byType)(CardType_1.CardType.CORPORATION))
        .map(ClientCardManifest_1.toName)
        .filter((name) => name !== CardName_1.CardName.BEGINNER_CORPORATION);
}
exports.default = vue_1.default.extend({
    name: 'CorporationsFilter',
    props: {
        corporateEra: {
            type: Boolean,
        },
        prelude: {
            type: Boolean,
        },
        prelude2: {
            type: Boolean,
        },
        venusNext: {
            type: Boolean,
        },
        colonies: {
            type: Boolean,
        },
        turmoil: {
            type: Boolean,
        },
        promoCardsOption: {
            type: Boolean,
        },
        communityCardsOption: {
            type: Boolean,
        },
        moonExpansion: {
            type: Boolean,
        },
        pathfindersExpansion: {
            type: Boolean,
        },
    },
    data() {
        const x = GameModule_1.GAME_MODULES.map((module) => [module, []]);
        const cardsByModule = Object.fromEntries(x);
        (0, ClientCardManifest_1.getCards)((0, ClientCardManifest_1.byType)(CardType_1.CardType.CORPORATION)).forEach((card) => {
            if (card.name !== CardName_1.CardName.BEGINNER_CORPORATION) {
                cardsByModule[card.module].push(card.name);
            }
        });
        GameModule_1.GAME_MODULES.forEach((module) => cardsByModule[module].sort());
        return {
            cardsByModule: cardsByModule,
            customCorporationsList: false,
            selectedCorporations: [
                ...corpCardNames('base'),
                ...this.corporateEra ? corpCardNames('corpera') : [],
                ...this.prelude ? corpCardNames('prelude') : [],
                ...this.prelude2 ? corpCardNames('prelude2') : [],
                ...this.venusNext ? corpCardNames('venus') : [],
                ...this.colonies ? corpCardNames('colonies') : [],
                ...this.turmoil ? corpCardNames('turmoil') : [],
                ...this.promoCardsOption ? corpCardNames('promo') : [],
                ...this.communityCardsOption ? corpCardNames('community') : [],
                ...this.moonExpansion ? corpCardNames('moon') : [],
                ...this.pathfindersExpansion ? corpCardNames('pathfinders') : [],
            ],
            GAME_MODULES: GameModule_1.GAME_MODULES,
            MODULE_NAMES: GameModule_1.MODULE_NAMES,
        };
    },
    methods: {
        getItemsByGroup(group) {
            if (group === 'All')
                return GameModule_1.GAME_MODULES.map((module) => this.cardsByModule[module]).flat();
            const corps = this.cardsByModule[group];
            if (corps === undefined) {
                console.log('module %s not found', group);
                return [];
            }
            else {
                return corps.slice();
            }
        },
        selectAll(group) {
            const items = this.getItemsByGroup(group);
            for (const item of items) {
                if (this.selectedCorporations.includes(item) === false) {
                    this.selectedCorporations.push(item);
                }
            }
        },
        removeFromSelection(cardName) {
            const itemIdx = this.selectedCorporations.indexOf(cardName);
            if (itemIdx !== -1) {
                this.selectedCorporations.splice(itemIdx, 1);
            }
        },
        selectNone(group) {
            const items = this.getItemsByGroup(group);
            for (const item of items) {
                this.removeFromSelection(item);
            }
        },
        invertSelection(group) {
            const items = this.getItemsByGroup(group);
            for (const idx in items) {
                if (this.selectedCorporations.includes(items[idx])) {
                    this.removeFromSelection(items[idx]);
                }
                else {
                    this.selectedCorporations.push(items[idx]);
                }
            }
        },
        watchSelect(module, enabled) {
            enabled ? this.selectAll(module) : this.selectNone(module);
        },
        expansions(corporation) {
            var _a, _b;
            return (_b = (_a = (0, ClientCardManifest_1.getCard)(corporation)) === null || _a === void 0 ? void 0 : _a.compatibility) !== null && _b !== void 0 ? _b : [];
        },
        icon(module) {
            let suffix = module;
            if (module === 'colonies')
                suffix = 'colony';
            if (module === 'moon')
                suffix = 'themoon';
            return `create-game-expansion-icon expansion-icon-${suffix}`;
        },
    },
    watch: {
        selectedCorporations(value) {
            this.$emit('corporation-list-changed', value);
        },
        corporateEra(enabled) {
            this.watchSelect('corpera', enabled);
        },
        prelude(enabled) {
            this.watchSelect('prelude', enabled);
        },
        venusNext(enabled) {
            this.watchSelect('venus', enabled);
        },
        colonies(enabled) {
            this.watchSelect('colonies', enabled);
        },
        turmoil(enabled) {
            this.watchSelect('turmoil', enabled);
        },
        promoCardsOption(enabled) {
            this.watchSelect('promo', enabled);
        },
        communityCardsOption(enabled) {
            this.watchSelect('community', enabled);
        },
        moonExpansion(enabled) {
            this.watchSelect('moon', enabled);
        },
        pathfindersExpansion(enabled) {
            this.watchSelect('pathfinders', enabled);
        },
    },
});
