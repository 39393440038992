"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PlayerResources_vue_1 = require("@/client/components/overview/PlayerResources.vue");
const PlayerTags_vue_1 = require("@/client/components/overview/PlayerTags.vue");
const PlayerStatus_vue_1 = require("@/client/components/overview/PlayerStatus.vue");
const utils_1 = require("@/common/utils/utils");
const vueRoot_1 = require("@/client/components/vueRoot");
const utils_2 = require("@/common/utils/utils");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const CardType_1 = require("@/common/cards/CardType");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const Phase_1 = require("@/common/Phase");
exports.default = vue_1.default.extend({
    name: 'PlayerInfo',
    props: {
        player: {
            type: Object,
        },
        playerView: {
            type: Object,
        },
        firstForGen: {
            type: Boolean,
            default: false,
        },
        actionLabel: {
            type: String,
            default: '',
        },
        playerIndex: {
            type: Number,
        },
        hideZeroTags: {
            type: Boolean,
            default: false,
        },
        isTopBar: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
        PlayerResources: PlayerResources_vue_1.default,
        PlayerTags: PlayerTags_vue_1.default,
        'player-status': PlayerStatus_vue_1.default,
    },
    computed: {
        tooltipCss() {
            return 'tooltip tooltip-' + (this.isTopBar ? 'bottom' : 'top');
        },
        Phase() {
            return Phase_1.Phase;
        },
    },
    methods: {
        isPinned(playerIndex) {
            return (0, vueRoot_1.vueRoot)(this).getVisibilityState('pinned_player_' + playerIndex);
        },
        pin(playerIndex) {
            return (0, vueRoot_1.vueRoot)(this).setVisibilityState('pinned_player_' + playerIndex, true);
        },
        unpin(playerIndex) {
            return (0, vueRoot_1.vueRoot)(this).setVisibilityState('pinned_player_' + playerIndex, false);
        },
        pinPlayer() {
            let hiddenPlayersIndexes = [];
            const playerPinned = this.isPinned(this.playerIndex);
            hiddenPlayersIndexes = (0, utils_2.range)(this.playerView.players.length - 1);
            if (!playerPinned) {
                this.pin(this.playerIndex);
                hiddenPlayersIndexes = hiddenPlayersIndexes.filter((index) => index !== this.playerIndex);
            }
            for (let i = 0; i < hiddenPlayersIndexes.length; i++) {
                if (hiddenPlayersIndexes.includes(i)) {
                    this.unpin(i);
                }
            }
        },
        buttonLabel() {
            return this.isPinned(this.playerIndex) ? 'hide' : 'show';
        },
        togglePlayerDetails() {
            var _a;
            if (this.player.color === ((_a = this.playerView.thisPlayer) === null || _a === void 0 ? void 0 : _a.color)) {
                const el = document.getElementsByClassName('sidebar_icon--cards')[0];
                el.click();
                return;
            }
            this.pinPlayer();
        },
        getClasses() {
            return `player-info ${(0, utils_1.playerColorClass)(this.player.color, 'bg_transparent')}`;
        },
        numberOfPlayedCards() {
            return this.player.tableau.length;
        },
        availableBlueActionCount() {
            return this.player.availableBlueCardActionCount;
        },
        getCorporationName() {
            const cards = this.player.tableau;
            const corporationCards = cards
                .filter((card) => { var _a; return ((_a = (0, ClientCardManifest_1.getCard)(card.name)) === null || _a === void 0 ? void 0 : _a.type) === CardType_1.CardType.CORPORATION; })
                .map((card) => card.name);
            return corporationCards.length === 0 ? [''] : corporationCards;
        },
    },
});
