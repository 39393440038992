"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const PaymentWidgetMixin_1 = require("@/client/mixins/PaymentWidgetMixin");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
exports.default = vue_1.default.extend({
    name: 'PaymentUnitComponent',
    props: {
        value: {
            type: Number,
        },
        unit: {
            type: String,
        },
        description: {
            type: String,
        },
    },
    components: {
        AppButton: AppButton_vue_1.default,
    },
    methods: Object.assign({}, PaymentWidgetMixin_1.PaymentWidgetMixin.methods),
});
