"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const BoardName_1 = require("@/common/boards/BoardName");
const RandomMAOptionType_1 = require("@/common/ma/RandomMAOptionType");
const Types_1 = require("@/common/turmoil/Types");
const i18n_1 = require("@/client/directives/i18n");
const boardColorClass = {
    [BoardName_1.BoardName.THARSIS]: 'game-config board-tharsis map',
    [BoardName_1.BoardName.HELLAS]: 'game-config board-hellas map',
    [BoardName_1.BoardName.ELYSIUM]: 'game-config board-elysium map',
    [BoardName_1.BoardName.AMAZONIS]: 'game-config board-amazonis map',
    [BoardName_1.BoardName.ARABIA_TERRA]: 'game-config board-arabia_terra map',
    [BoardName_1.BoardName.VASTITAS_BOREALIS]: 'game-config board-vastitas_borealis map',
    [BoardName_1.BoardName.TERRA_CIMMERIA]: 'game-config board-terra_cimmeria map',
};
exports.default = vue_1.default.extend({
    name: 'game-setup-detail',
    props: {
        playerNumber: {
            type: Number,
        },
        gameOptions: {
            type: Object,
        },
        lastSoloGeneration: {
            type: Number,
        },
    },
    computed: {
        isPoliticalAgendasOn() {
            return (this.gameOptions.politicalAgendasExtension !== Types_1.AgendaStyle.STANDARD);
        },
        boardColorClass() {
            return boardColorClass[this.gameOptions.boardName];
        },
        escapeVelocityDescription() {
            var _a;
            const { escapeVelocityThreshold, escapeVelocityPenalty, escapeVelocityPeriod, escapeVelocityBonusSeconds } = (_a = this.gameOptions) !== null && _a !== void 0 ? _a : {};
            if (escapeVelocityThreshold === undefined || escapeVelocityPenalty === undefined || escapeVelocityPeriod === undefined || escapeVelocityBonusSeconds === undefined) {
                return '';
            }
            return (0, i18n_1.translateTextWithParams)('After ${0} min, reduce ${1} VP every ${2} min. (${3} bonus sec. per turn.)', [escapeVelocityThreshold.toString(), escapeVelocityPenalty.toString(), escapeVelocityPeriod.toString(), escapeVelocityBonusSeconds.toString()]);
        },
        RandomMAOptionType() {
            return RandomMAOptionType_1.RandomMAOptionType;
        },
    },
});
