"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants = require("@/common/constants");
const HTTPResponseCode = require("@/client/utils/HTTPResponseCode");
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const vueRoot_1 = require("@/client/components/vueRoot");
const paths_1 = require("@/common/app/paths");
exports.default = vue_1.default.extend({
    name: 'LoadGameForm',
    components: {
        AppButton: AppButton_vue_1.default,
    },
    data() {
        return {
            gameId: undefined,
            rollbackCount: 0,
        };
    },
    methods: {
        loadGame() {
            const gameId = this.gameId;
            const rollbackCount = this.rollbackCount;
            if (gameId === undefined) {
                alert('Specify a game id');
                return;
            }
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', paths_1.paths.LOAD_GAME);
            xhr.onerror = function () {
                alert('Error loading game');
            };
            xhr.onload = () => {
                if (xhr.status === HTTPResponseCode.OK) {
                    const response = xhr.response;
                    if (response.players.length === 1) {
                        window.location.href = 'player?id=' + response.players[0].id;
                        return;
                    }
                    else {
                        window.history.replaceState(response, `${constants.APP_NAME} - Game`, 'game?id=' + response.id);
                        (0, vueRoot_1.vueRoot)(this).game = response;
                        (0, vueRoot_1.vueRoot)(this).screen = 'game-home';
                    }
                }
                else {
                    alert('Unexpected server response');
                }
            };
            const loadGameFormModel = {
                gameId: gameId,
                rollbackCount: rollbackCount,
            };
            xhr.responseType = 'json';
            xhr.send(JSON.stringify(loadGameFormModel));
        },
    },
    computed: {
        APP_NAME() {
            return constants.APP_NAME;
        },
    },
});
